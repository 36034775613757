<template>
  <section class="nginx">
    <div class="f mb-20 w">
      <div>
        <h1>Генератор NGINX <span class="green">•</span></h1>
        <p>Выполните все команды по очереди</p>
      </div>
      <div class="flex">
        <a class="btn mr-14" target="_blank" href="https://cp.sprinthost.ru/customer/dns/index?domain=yablonev.art">Добавить A запись</a>
        <a class="btn" target="_blank" href="https://server.yablonev.art/services/nginx">Открыть сервер</a>
      </div>
    </div>
    <div class="fields w">
      <div class="form-control">
        <label>Тип проекта</label>
        <div class="options">
          <div class="option" :class="{not_selected: type !== 'frontend'}" @click="type = 'frontend'">Frontend</div>
          <div class="option" :class="{not_selected: type !== 'backend'}" @click="type = 'backend'">Backend</div>
        </div>
      </div>
      <div v-show="type === 'backend'" class="form-control">
        <label>Node.js API + Socket (Single Port)</label>
        <div class="options">
          <div class="option" :class="{not_selected: use_socket !== true}" @click="use_socket = true">Да</div>
          <div class="option" :class="{not_selected: use_socket !== false}" @click="use_socket = false">Нет</div>
        </div>
      </div>
      <div class="form-control">
        <label>Домен</label>
        <input v-model="domain" />
      </div>
      <div class="form-control" v-show="type === 'backend'">
        <label>Порт</label>
        <input v-model="port" />
      </div>
    </div>
    <div v-for="(commandNodes, cN) of commands" :key="cN" tabindex="0">
      <div class="action_result" v-show="node.show !== false" :key="`${n}-${node.type}`" v-for="(node, n) of commandNodes">
        <pre v-if="node.type === 'code'" v-highlightjs="node.content"><code :class="node.syntax || 'bash'"></code></pre>
        <p v-if="node.type === 'text'" v-html="node.content" />
      </div>
    </div>
  </section>
</template>

<script>
import pass from 'pass'

export default {
  name: 'Nginx',
  data() {
    return {
      type: 'frontend',
      password: false,
      use_socket: false,
      protection_login: 'yablonev',
      protection_password: 'some_secret_key',
      protection_message: 'Staff only',
      md5_protection_password: '',
      domain: 'system.yablonev.art',
      port: 3000,
    }
  },
  watch: {
    protection_password: {
      immediate: true,
      handler() {
        pass.generate(this.protection_password, (_, protection_password) => {
          this.md5_protection_password = protection_password
        }, { algorithm: 'md5' })
      }
    }
  },
  computed: {
    protection_code() {
      return this.password ? `
    location ~* / {
      auth_basic "${this.protection_message}";
      auth_basic_user_file /var/www/${this.domain}/htpasswd;
      try_files $uri $uri/ /index.html;
    }
` : ''
    },
    frontend_code() {
      return `root /var/www/${this.domain}/html;

rewrite ^(/.*)\\.html(\\?.*)?$ $1$2 permanent;

index index.html;
try_files $uri.html $uri/ $uri =404;`
    },
    commands() {
      return [
        [
          { type: 'code', show: true, content: `sudo nano /etc/nginx/sites-available/${this.domain}.conf` },
        ],
        [
          { type: 'code', show: true, syntax: 'nginx', content: `server {
    listen 80;
    listen [::]:80;

    server_name ${this.domain};
    root /var/www/${this.domain}/html;
    index index.html index.xml;
}`, }
        ],
        [
          { type: 'code', show: true, content: `sudo ln -s /etc/nginx/sites-available/${this.domain}.conf /etc/nginx/sites-enabled/` }
        ],
        [
          { type: 'code', show: true, content: `sudo nginx -t` }
        ],
        [
          { type: 'code', show: true, content: `sudo service nginx reload` }
        ],
        [
          { type: 'code', show: true, content: `sudo certbot --nginx certonly --register-unsafely-without-email` }
        ],
        [
          { type: 'code', show: true, content: `sudo nano /etc/nginx/sites-available/${this.domain}.conf` }
        ],
        [
          { type: 'code', show: this.type === 'backend' && !this.use_socket, syntax: 'nginx', content: `server {
    listen 80;
    listen [::]:80;

    server_name ${this.domain};
    return 301 https://${this.domain}$request_uri;
}

server {
    listen 443 ssl;
    listen [::]:443 ssl;
    http2 on;

    server_name ${this.domain};
    ${this.protection_code}
    ssl_certificate /etc/letsencrypt/live/${this.domain}/fullchain.pem;
    ssl_certificate_key /etc/letsencrypt/live/${this.domain}/privkey.pem;
    ssl_trusted_certificate /etc/letsencrypt/live/${this.domain}/chain.pem;

    include snippets/ssl-params.conf;

    location / {
      proxy_set_header X-Real-IP $remote_addr;
      proxy_set_header X-Forwarded-For $proxy_add_x_forwarded_for;
      proxy_set_header X-Forwarded-Proto $scheme;
      proxy_set_header Host $http_host;
      proxy_set_header X-NginX-Proxy true;

      proxy_redirect off;
      proxy_pass http://localhost:${this.port};
    }
}` },
        ],
        [
          { type: 'code', show: this.type === 'backend' && this.use_socket, syntax: 'nginx', content: `server {
    listen 80;
    listen [::]:80;

    server_name ${this.domain};
    return 301 https://${this.domain}$request_uri;
}

server {
    listen 443 ssl;
    listen [::]:443 ssl;
    http2 on;

    server_name ${this.domain};
    ${this.protection_code}
    ssl_certificate /etc/letsencrypt/live/${this.domain}/fullchain.pem;
    ssl_certificate_key /etc/letsencrypt/live/${this.domain}/privkey.pem;
    ssl_trusted_certificate /etc/letsencrypt/live/${this.domain}/chain.pem;

    include snippets/ssl-params.conf;

    location / {
        proxy_set_header X-Real-IP $remote_addr;
        proxy_set_header X-Forwarded-For $proxy_add_x_forwarded_for;
        proxy_set_header X-Forwarded-Proto $scheme;
        proxy_set_header Host $http_host;
        proxy_set_header X-NginX-Proxy true;

        proxy_redirect off;

        # WebSocket (wss)
        proxy_http_version 1.1;
        proxy_set_header Upgrade $http_upgrade;
        proxy_set_header Connection "upgrade";

        proxy_pass http://localhost:${this.port};
    }
}` },
        ],
        [
          { type: 'code', show: this.type === 'frontend', syntax: 'nginx', content: `server {
    listen 80;
    listen [::]:80;

    server_name ${this.domain};
    return 301 https://${this.domain}$request_uri;
}

server {
    listen 443 ssl;
    listen [::]:443 ssl;
    http2 on;

    server_name ${this.domain};
    root /var/www/${this.domain}/html;
    index index.html index.xml;
    ${this.protection_code}
    ssl_certificate /etc/letsencrypt/live/${this.domain}/fullchain.pem;
    ssl_certificate_key /etc/letsencrypt/live/${this.domain}/privkey.pem;
    ssl_trusted_certificate /etc/letsencrypt/live/${this.domain}/chain.pem;

    include snippets/ssl-params.conf;

    location / {
      try_files $uri $uri/ /index.html;
    }
}` },
        ],
        [
          { type: 'code', show: true, content: `sudo nginx -t` },
        ],
        [
          { type: 'code', show: true, content: `sudo service nginx reload` },
        ],
        [
          { type: 'code', show: true, content: `cd /var/www/` },
        ],
        [
          { type: 'code', show: true, content: `mkdir ${this.domain}` },
        ],
        [
          { type: 'code', show: true, content: `cd ${this.domain}` },
        ],
        [
          { type: 'code', show: true, content: `mkdir html build` },
        ],
        [
          { type: 'code', show: true, content: `chmod 777 *` },
        ],
        [
          { type: 'code', show: this.password, content: `echo "${this.protection_login}:${this.md5_protection_password}" >> /var/www/${this.domain}/htpasswd` },
        ],
      ]
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/pages/nginx'
</style>